<template>
  <section class="section1">
    <div class="text">
      <h1>Šnečí farma <br /><span class="vlkos">Vlkoš</span></h1>
      <h2>Od roku 2013 s láskou chováme naše šneky už od vajíček</h2>
    </div>
    <div class="overlay"></div>

    <!-- <button class="become">Staňte se naším farmářem</button> -->
  </section>
  <section class="section2">
    <div
      class="left"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <img
        src="../assets/284049294_3298191217076997_6261973062448697004_n.jpg"
        alt=""
      />
    </div>
    <div class="section2-box">
      <div class="table">
        <h2>NÁŠ FARMÁŘ</h2>
        <p>Nic neřeší, protože jsme s ním a pomáháme mu!</p>
      </div>

      <div class="photo"></div>
      <div class="text">
        <h2>Kompletní program pro šnečí farmáře</h2>
        <p style="text-align: center">
          Jste nadšeni do chovu šneků a je to pro vás koníček, který by mohl být
          zaměstnáním?
        </p>
        <div class="ul">
          <p>
            <strong
              >Staňte se naším farmářem a výrazně si zjednodušíte cestu <br />
              <br />
              Protože:</strong
            >
          </p>
          <ul>
            <li>vám dodáme skvělé šneky - moraváky do začátku</li>
            <li>
              vám dodáme námi vyvinuté velmi kvalitní
              <router-link to="/feed">krmivo</router-link>
            </li>
            <li>budeme vám k dispozici pro konzultace</li>
            <li>
              pokud dodržíte pravidla spolupráce - jsme schopni vám i šneky
              odkoupit
            </li>
          </ul>
        </div>
      </div>
      <div class="section2-box-logo">
        <!-- <img src="../assets/logo.png" alt="" height="70px" /> -->
        <button class="become">
          <a href="tel:+420734488350">Staňte se naším farmářem</a>
        </button>
      </div>
    </div>

    <div
      class="right"
      data-aos="fade-down"
      data-aos-duration="1000"
      data-aos-once="true"
    >
      <img src="../assets/sneci-farma-vlkos-8.jpg" alt="" />
    </div>
  </section>
  <section class="section3">
    <div class="section3-heading">
      <h2>Jaké šneky s námi můžete chovat?</h2>
    </div>
    <div class="snails">
      <div
        class="snail1"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div class="text">
          <h2>Helix Aspersa Müller</h2>
          <p>(šnek malý šedý Petit-Gris)</p>
          <ul>
            <li>
              <p><strong>Ve světě žádanější a známější.</strong></p>
            </li>
            <li>
              <p>
                Je <strong>velmi chutný </strong>a
                <strong>nevyžaduje velkou přípravu při zpracování</strong>, může
                být totiž <strong>konzumován v celku</strong>, samozřejmě bez
                ulity.
              </p>
            </li>
            <li>
              <p>
                Tento šnek je také charakterizován
                <strong>rychlejším tempem růstu</strong>

                a dosahuje sexuální <strong>zralosti po 5–6 měsících.</strong>
              </p>
            </li>
            <li>
              <p>
                Ačkoli roste rychleji, je menší, protože váží pouze 8–15 gramů a
                klade v rozmezí 80-120 vajec
              </p>
            </li>
          </ul>
        </div>
        <div class="photo">
          <img src="../assets/maly.jpg" alt="" />
        </div>
      </div>
      <div
        class="snail2"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div class="text">
          <h2>Helix Aspersa Maxima</h2>
          <p>(africký šnek velký šedý Gros-Gris)</p>
          <ul>
            <li>
              <p>
                <strong>Byl přivezen do Francie ze severní Afriky.</strong>
              </p>
            </li>
            <li>
              <p>
                Helix Aspersa Maxima pochází z čeledi Helicidae. Dosahuje
                sexuální zralosti během 4–5 měsíců
              </p>
            </li>
            <li>
              <p>
                To znamená, že <strong>jedna sezóna</strong>, tj.
                <strong>od jara do podzimu, je dostačující</strong> k získání
                správné velikosti pro účely
                <strong>zpracování a rozmnožování.</strong>
              </p>
            </li>
            <li>
              <p>
                Dospělý druh Helix Aspersa Maxima váží 15–30 gramů a klade v
                rozmezí 120–200 vajec
              </p>
            </li>
          </ul>
        </div>
        <div class="photo">
          <img src="../assets/velky.jpg" alt="" />
        </div>
      </div>
    </div>
  </section>
  <section class="section5">
    <h2>Podívejte se, jak šel čas na naší farmě.</h2>
    <div class="timeline">
      <div class="container left">
        <div class="content">
          <h2>2020</h2>
          <h3>Dobroty a degustace</h3>
          <p>
            Pracujeme na nových stránkách šnečí farmy a e‑shopu
            <a href="https://www.dobrotyzulity.cz/">dobrotyzulity.cz</a>.
            Spouštíme oficiální prodej šnečích produktů a degustací.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="content">
          <h2>2019</h2>
          <h3>Chov, kaviár, sliz a dobroty</h3>
          <p>
            Rozšiřujeme nová pole. Začínáme nabízet šnečí kaviár a sliz pro
            kosmetické účely. Se státní správou finišujeme provozovnu a stále
            ladíme naše postupy výroby a přípravy šnečích produktů. Připravujeme
            program šnečích degustací na akcích jak na farmě, tak u našich
            klientů. Stále prodáváme šneky pro chov, krmivo a podporujeme naše
            farmáře.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="content">
          <h2>2018</h2>
          <h3>Pořizujeme druh Helix Aspera Müller, připravujeme Dobroty</h3>
          <p>
            Chov je stabilizovaný, budujeme nový chladicí box na 2 tuny šneků a
            vlastní líheň. Prodáváme chovné šneky a šnečí maso pro zpracování do
            restaurací. Do chovu přidáváme další druh Helix Aspersa Müller,
            který je v kulinářském oboru žádaný, protože je velmi chutný a méně
            náročný na přípravu. Připravujeme vlastní šnečí produkty a řešíme s
            hygienickou stanicí a veterinární správou provozovnu pro výrobu
            šnečích produktů.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="content">
          <h2>2017</h2>
          <h3>Opět rozšiřujeme, měníme a chováme</h3>
          <p>
            Měníme některá chovná pole, protože nevyhovují. Rozšiřujeme chovné
            plochy. Zavádíme do chodu program Náš Farmář, který je doprovodnou
            součástí prodeje chovných šneků a slouží k pomoci začínajícím
            farmářům.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="content">
          <h2>2016</h2>
          <h3>Rozšiřujeme políčka a řešíme líheň</h3>
          <p>
            Pronajímáme si první straší objekt. K živým šnekům po zazimování
            přidáváme na chovná pole i vajíčka a ještě více rozšiřujeme množství
            chovaných šneků.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="content">
          <h2>2015</h2>
          <h3>Krmivo je základ</h3>
          <p>
            Na základě francouzského originálního složení z UFAC Francie, kterou
            se nám podařilo získat ve spolupráci s předními odborníky na výživu
            hospodářských zvířat společnosti Trouw Nutrition Biofaktory s.r.o. v
            České republice, řešíme vlastní krmnou směs pro všechny druhy
            hlemýžďů. Směs je přizpůsobená pro chov v našich podmínkách a
            obsahuje všechny nezbytné vitamínové složky přes analytické složky
            až po nutriční doplňkové látky. Zjednodušeně řečeno od vápníku až k
            vitamínům.
          </p>
        </div>
      </div>
      <div class="container left">
        <div class="content">
          <h2>2014</h2>
          <h3>Šnečí farma je na světě</h3>
          <p>
            První znalosti a poznatky z chovu i dlouholeté zkušenosti odborníka
            v oblasti hlemýžďů MVDr. Miroslava Pavlíčka přispěly k zdárnému
            vytvoření naší farmy. Ne malou mírou se podílel také Doc. Ing.
            Zdeněk Frydrych CSc. Pro zimní spánek máme již připravený malý
            chladící box.
          </p>
        </div>
      </div>
      <div class="container right">
        <div class="content">
          <h2>2013</h2>
          <h3>Začínáme s chovem</h3>
          <p>
            Pořizujeme první šneky Helix Aspersa Maxima a máme první malé
            políčko o 1000 m². Vše řešíme na základě chovných studií z Francie a
            Itálie a zavádíme postupy správného chovu.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="dobroty">
    <div class="dobroty-left"><img src="../assets/dobroty.jpg" alt="" /></div>
    <div class="dobroty-right">
      <h2>Pro milovníky gurmánských specialit</h2>
      <p>
        Provozujeme také e-shop
        <strong
          ><a href="https://www.dobrotyzulity.cz/"
            >"Dobroty&nbsp;z&nbsp;ulity"</a
          ></strong
        >, kde najdete širokou nabídku delikates ze šnečího masa. Mezi
        nejoblíbenější produkty patří
        <strong>šnečí paštiky, šneci v ulitě po Burgundsku</strong> či
        <strong>šnečí maso s balkánským sýrem.</strong>

        Všechny produkty jsou vyráběny ručně z čerstvých surovin přímo na farmě.
      </p>
      <p>
        Pokud chcete ochutnat tyto jedinečné delikatesy, navštivte stránky
        <a href="https://www.dobrotyzulity.cz/">Dobroty&nbsp;z&nbsp;ulity</a> a
        objevte svět šnečích specialit.
      </p>
    </div>
  </section>
  <section class="section4">
    <div class="text">
      <h2>Rozhodujete se pro chov šneků?</h2>
    </div>
    <div class="button">
      <button class="become">
        <a href="tel:+420734488350">Staňte se naším farmářem</a>
      </button>
    </div>
  </section>

  <footer>
    <div class="contact">
      <div class="left">
        <h2>Veškeré dotazy Vám rádi zodpovíme</h2>
        <h3>Telefonicky nebo emailem.</h3>
        <br />
        <h2>
          <a href="tel:+420734488350">+420 734 488 350</a>
        </h2>

        <h3>
          <a href="mailto:jiri@snecifarmavlkos.cz">jiri@snecifarmavlkos.cz</a>
        </h3>
      </div>
      <div class="right">
        <div class="photos">
          <div class="photo1">
            <img src="../assets/snek-uvod.jpg" alt="" />
          </div>
          <div class="photo2">
            <img src="../assets/20250102_123956.jpg" alt="" />
          </div>
          <div class="photo3">
            <img
              src="../assets/284049294_3298191217076997_6261973062448697004_n.jpg"
              alt=""
            />
          </div>
          <div class="photo4"><img src="../assets/header.jpg" alt="" /></div>
          <div class="photo5">
            <img src="../assets/sneci-farma-vlkos-11.jpg" alt="" />
          </div>
          <div class="photo6">
            <img src="../assets/sneci-farma-vlkos-8.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="copy"><p>© 2013 - 2025 Šnečí farma Vlkoš</p></div>
    <div class="made-by">
      <p>Vytvořil: <a href="https://www.weby-peba.cz/">Weby PeBa</a></p>
    </div>
  </footer>
</template>

<script></script>
<style scoped>
.section2 {
  width: 100%;
  height: 850px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
  max-width: 2000px;
}

.section2 .left {
  position: absolute;
  /* background-image: url(./assets/284049294_3298191217076997_6261973062448697004_n.jpg); */
  width: 40%;
  height: 700px;
  background-position: 45% -200%;
  z-index: 1;
  margin-top: 150px;
}
.section2 .left img {
  width: 100%;
  height: 100%;
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.158);
}
.section2 .right {
  width: 50%;
  height: 550px;
  /* background-image: url(./assets/sneci-farma-vlkos-11.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: 100%; */
  position: absolute;
  right: 0;
  top: 20px;
}
.section2 .right img {
  width: 100%;
  height: 100%;
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.158);
}
.section2-box {
  position: relative;
  width: 60%;
  margin: 0 auto;
  background-color: var(--light-brown);
  display: flex;
  z-index: 2;
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.158);
}
.section2-box-logo {
  height: 110px;
  width: 350px;
  position: absolute;
  right: 50px;
  bottom: -50px;
  transform: translate(50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section2-box .table {
  position: absolute;
  top: -100px;
  left: -50px;
  transform: rotate(-15deg);
  width: 200px;
  background-color: var(--light-brown);
  z-index: 2;
  box-shadow: -4px 0px 14px rgba(0, 0, 0, 0.329);
  padding: 1rem;
  text-align: center;
}
.section2-box .photo {
  width: 45%;
  clip-path: polygon(
    1% 0,
    2% 98%,
    88% 100%,
    89% 94%,
    88% 93%,
    87% 91%,
    86% 91%,
    82% 88%,
    81% 86%,
    80% 83%,
    80% 82%,
    80% 80%,
    80% 78%,
    78% 74%,
    82% 71%,
    83% 67%,
    82% 64%,
    82% 62%,
    82% 61%,
    84% 60%,
    84% 59%,
    81% 55%,
    81% 54%,
    83% 52%,
    82% 49%,
    82% 48%,
    83% 47%,
    83% 46%,
    81% 40%,
    81% 40%,
    82% 38%,
    83% 38%,
    83% 35%,
    82% 34%,
    83% 32%,
    82% 30%,
    81% 28%,
    85% 26%,
    83% 22%,
    81% 19%,
    80% 17%,
    80% 16%,
    81% 16%,
    82% 15%,
    83% 14%,
    84% 13%,
    84% 11%,
    86% 9%,
    87% 8%,
    88% 7%,
    88% 6%,
    88% 4%,
    88% 3%,
    88% 1%,
    88% 0%,
    88% 0%
  );
}
.section2-box .text {
  width: 55%;
  padding: 2rem 2rem 2rem 0;
}
.section2-box .text .ul {
  padding-top: 1rem;
  width: 90%;
}
.section2-box .text ul {
  padding: 1rem;
}
.section2-box .text ul li {
  padding-top: 10px;
}
.section2-box .text h2 {
  padding-bottom: 1rem;
  text-align: center;
}
.section2-box .photo {
  background-image: url(../assets/snek-uvod.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 30% 50%;

  border-block: 2rem solid var(--light-brown);
  border-left: 1rem solid var(--light-brown);
}
/* section3 */
.section3 {
  width: 95%;
  display: flex;
  padding-top: 2rem;
  align-items: center;
  gap: 1rem;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 2rem;
  max-width: 2000px;
}
.section3 .section3-heading {
  text-align: center;
  padding-block: 2rem;
}
.section3 .section3-heading h2 {
  font-size: 1.8rem;
}
.section3 .snails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
}
.section3 .snails .snail1 .text ul li,
.section3 .snails .snail2 .text ul li {
  padding-top: 1rem;
  list-style-type: none;
}
.section3 .snail1,
.section3 .snail2 {
  width: 65%;
  height: 500px;
  display: flex;
  background-color: var(--light-brown);
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.158);
}
.section3 .snail2 {
  flex-direction: row-reverse;
}

.section3 .snail1 .text,
.section3 .snail2 .text {
  width: 50%;
  padding: 1rem;
}
.section3 .snail1 .photo,
.section3 .snail2 .photo {
  width: 50%;
}
.section3 .snail1 .photo img,
.section3 .snail2 .photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* section 4 */
.section4 {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-block: 3rem;
  gap: 2rem;
  padding-bottom: 80px;

  padding-left: 15%;
  max-width: 2000px;
  margin: 0 auto;
}
.section4 .text {
  text-align: end;
}
/* section5 */
.section5 {
  width: 100%;
  background-color: var(--dark-brown);
}
.section5 h2 {
  color: var(--dark-brown-text);
  text-align: center;
  padding-block: 2rem;
}
.timeline {
  width: 90%;
  height: 90%;
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
}
.timeline .container {
  width: 50%;
  padding: 10px 40px;
  position: relative;
}
.timeline .container::after {
  content: "";
  position: absolute;
  width: 35px;
  height: 35px;
  right: -17px;
  top: 15px;
  z-index: 1;
  background-color: var(--dark-brown);
  background-image: url(../assets/snail.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.timeline .container .content {
  padding: 20px 30px;
  background-color: var(--light-brown);
  position: relative;
  border-radius: 6px;
}
.timeline .container .content h2 {
  padding: 0;
}
.timeline .container .content h3 {
  padding-block: 1rem;
}
.timeline .container .content p {
  line-height: 25px;
}
.timeline .left {
  text-align: end;
  left: 0;
}
.timeline .left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--light-brown);
}
.timeline .right {
  text-align: start;
  left: 50%;
}
.timeline .right::after {
  left: -16px;
}
.timeline .right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--light-brown) transparent transparent;
}
.timeline .right h2 {
  text-align: start;
}
.timeline .left h2 {
  text-align: end;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: var(--white);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}
/* dobroty */
.dobroty {
  width: 100%;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 2000px;
  margin: 0 auto;
  line-height: 25px;
}
.dobroty-left {
  width: 45%;
  height: 95%;
  position: absolute;
  left: 10%;
}
.dobroty-left img {
  width: 100%;
  height: 100%;
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.158);
}
.dobroty-right {
  width: 45%;
  height: 380px;
  background-color: var(--light-brown);
  box-shadow: 5px 4px 13px rgba(0, 0, 0, 0.158);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  left: 45%;
}
.dobroty-right h2 {
  font-size: 1.6rem;
  text-align: center;

  text-transform: uppercase;
}
.dobroty-right p {
  padding-top: 0.5rem;
}
@media screen and (max-width: 1360px) {
  .section4 {
    padding-left: 5%;
  }
}
@media screen and (max-width: 1150px) {
  .section4 {
    padding: 0;
    margin: 0;
    flex-direction: row;
    padding-block: 3rem;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .dobroty {
    flex-direction: column;
    height: auto;
  }
  .dobroty-left {
    position: static;
    width: 90%;
  }
  .dobroty-right {
    position: static;
    width: 80%;
    margin-top: -10%;
    height: auto;
  }
}
@media screen and (max-width: 1000px) {
  .section2 {
    height: 1350px;
    flex-direction: column;
    padding-top: 2rem;
    position: relative;
  }
  .section2 .left {
    position: relative;
    width: 100%;
    margin-top: 0;
  }
  .section2-box {
    width: 80%;
    margin-top: -200px;
  }
  .section2 .right {
    position: relative;
    margin-top: -150px;
    width: 100%;
    background-position-y: 200%;
  }
  .section2-box-logo {
    right: 50%;
    z-index: 30;
    bottom: -80px;
    width: 400px;
  }
  .section2-box.logo .become {
    border: 5px solid green;
  }
}
@media screen and (max-width: 900px) {
  .section3 .snail1,
  .section3 .snail2 {
    flex-direction: column;
    height: auto;
    width: 90%;
  }
  .section3 .snail1 .text,
  .section3 .snail2 .text {
    width: 100%;
  }
  .section3 .snail1 .photo,
  .section3 .snail2 .photo {
    width: 100%;
  }
}
@media screen and (max-width: 750px) {
  .section4 {
    flex-direction: column;
  }
  .timeline .container {
    width: 100%;
    padding: 10px 10px 10px 40px;
  }
  .timeline .left {
    text-align: start;
  }
  .timeline .left h2 {
    text-align: start;
  }
  .timeline .left::after {
    left: -16px;
  }
  .timeline::after {
    left: 0;
  }
  .timeline .right {
    left: 0;
  }
  .timeline .left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--light-brown) transparent transparent;
  }
  .dobroty-left {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .section4 {
    flex-direction: column;
  }
  .section4 .text {
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  .section2-box .text {
    width: 100%;
    padding: 1.5rem;
  }
  .section2-box .text .ul {
    width: 100%;
  }
  .section2-box .text ul {
    padding: 1rem 0 0 1rem;
  }
  .section2 .section2-box {
    flex-direction: column;
  }
  .section2 .section2-box .photo {
    height: 30%;
    width: 100%;
    border: 0.5rem solid var(--light-brown);
    clip-path: none;
    display: none;
  }
  .section2 .table {
    top: -115px;
    left: -30px;
  }
  .dobroty-right {
    padding: 20px 30px;
    line-height: 25px;
  }
}
</style>
