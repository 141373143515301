<template>
  <div id="app">
    <header :class="{ scrolled: isScrolled }">
      <div class="logo">
        <router-link style="padding-top: 5px" to="/"
          ><img src="./assets/WH_sneci_farma.svg" alt="" height="65px"
        /></router-link>
      </div>
      <div class="hamburger" @click="toggleMenu">
        <span :class="{ open: isOpen, scrolled: isScrolled }"></span>
        <span :class="{ open: isOpen, scrolled: isScrolled }"></span>
        <span :class="{ open: isOpen, scrolled: isScrolled }"></span>
      </div>
      <nav :class="{ open: isOpen, scrolled: isScrolled }">
        <ul>
          <li @click="closeMenu">
            <router-link to="/">Domů</router-link>
          </li>
          <li @click="closeMenu">
            <router-link to="/about">Prodej</router-link>
          </li>
          <li @click="closeMenu">
            <router-link to="/feed">Krmivo</router-link>
          </li>
          <li @click="closeMenu">
            <router-link to="/contact">Kontakt</router-link>
          </li>
        </ul>
      </nav>
    </header>

    <router-view />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";

export default {
  setup() {
    const isOpen = ref(false);
    const isScrolled = ref(false);

    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
    };
    const closeMenu = () => {
      isOpen.value = false;
    };
    const handleScroll = () => {
      isScrolled.value = window.scrollY > 50; // Změní stav, pokud scroll přesáhne 50px
    };
    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return { isOpen, toggleMenu, isScrolled, closeMenu };
  },
};
</script>

<style>
#app {
  overflow: hidden;
  min-height: 500px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: var(--dark-brown-text);
  position: fixed;
  width: 100%;
  height: 70px;
  font-family: var(--main-font);
  font-weight: 700;
  z-index: 10;
}
.scrolled {
  background-color: var(--dark-brown);
  color: var(--white);
  transition: all 0.2s ease-in-out;
}
.scrolled ul li a {
  color: var(--white);
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  width: 30%;
  display: flex;
}

/* Hamburger ikona */
.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.hamburger span {
  display: block;
  height: 3px;
  background: var(--white);
  transition: all 0.3s ease;
  border-radius: 2px;
}

.hamburger span.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger span.open:nth-child(2) {
  opacity: 0;
}

.hamburger span.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Menu */
nav {
  position: absolute;
  top: 70px;
  right: 0;
  /* background: var(--light-brown); */
  color: var(--dark-brown-text);
  /* width: 150px; */
  transform: translateX(100%);
  transition: transform 0.8s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

nav.open {
  transform: translateX(0);
  width: 150px;
  transition: transform 0.8s ease-in-out;
  /* border-left: 2px solid var(--dark-brown-text);
  border-bottom: 2px solid var(--dark-brown-text); */
  /* box-shadow: inset 39px 37px 130px -104px var(--dark-brown-text); */
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

nav ul li {
  padding: 10px 20px;
}

nav ul li a {
  color: var(--white);
  text-decoration: none;
  font-size: 1.1rem;
}

nav ul li a:hover {
  text-decoration: underline var(--dark-brown-text);
}
/* section 1*/

/* section 2 */

/* Responzivita */
@media (min-width: 768px) {
  .hamburger {
    display: none;
  }

  nav {
    position: static;
    transform: translateX(0);
    background: none;
    box-shadow: none;
    display: flex;
    justify-content: flex-end;
    width: 70%;
    text-shadow: var(--text-shadow-text);
  }

  nav ul {
    display: flex;
    width: 70%;
    justify-content: space-evenly;
  }

  nav ul li {
    padding: 0 15px;
  }
}
</style>
